export const PDF_NAME = 'PRO-Rapportdimpact-2024.pdf';

export const SKIP_ANIMATION_STORAGE_KEY = 'SKIP_ANIMATION';

export const MENU_HIERARCHY = [
  {
    label: 'Édito',
    focusLabel: 'Édito',
    icon: 'icon-1.svg',
    number: '1',
    color: 'chapter1',
    pages: [4,7],
  },
  {
    label: 'Estimer',
    focusLabel: 'Estimer',
    icon: 'icon-2.svg',
    number: '2',
    color: 'chapter2',
    pages: [8, 13],   
  },
  {
    label: 'Mesurer',
    focusLabel: 'Mesurer',
    icon: 'icon-3.svg',
    number: '3',
    color: 'chapter3',
    pages: [14, 17],
  },
  {
    label: 'Évaluer',
    focusLabel: 'Évaluer',
    icon: 'icon-4.svg',
    number: '4',
    color: 'chapter4',
    pages: [18, 21],
  },
  {
    label: 'Agir',
    focusLabel: 'Agir',
    icon: 'icon-5.svg',
    number: '5',
    color: 'chapter5',
    pages: [22,30],  
  },
];

export const MENU_DESKTOP_WIDTH = 300;
export const MENU_MOBILE_HEADER_HEIGHT = 85;
export const MENU_MOBILE_FOOTER_HEIGHT = 75;
export const NAVIGATION_HEIGHT = 52;
export const MOBILE_NAVIGATION_HEIGHT = 40;
export const PDF_PAGE_RATIO = 210 / 297;

const SHARE_URL = encodeURIComponent(window.location.href);
const SHARE_DESCRIPTION = encodeURI('Lisez le rapport d\'impact 2023 de Proparco');
const SHARE_TITLE = encodeURI('Rapport d\'impact 2023 - Proparco');
export const WEBSITE_URL = 'https://www.proparco.fr/fr';
export const FACEBOOK_SHARE_PARAMS = `http://www.facebook.com/share.php?u=${SHARE_URL}`;
export const X_SHARE_PARAMS = `http://x.com/intent/tweet?text=${SHARE_DESCRIPTION}&url=${SHARE_URL}`;
export const LINKEDIN_SHARE_PARAMS = ` https://www.linkedin.com/shareArticle?mini=true&url=${SHARE_URL}&title=${SHARE_TITLE}&summary=${SHARE_DESCRIPTION}`;

export const LINKEDIN_URL = 'https://www.linkedin.com/company/proparco/';

const CONFIG = {
  PDF_NAME,
  SKIP_ANIMATION_STORAGE_KEY,
  MENU_HIERARCHY,
  MENU_DESKTOP_WIDTH,
  MENU_MOBILE_HEADER_HEIGHT,
  MENU_MOBILE_FOOTER_HEIGHT,
  PDF_PAGE_RATIO,
  NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
  FACEBOOK_SHARE_PARAMS,
  X_SHARE_PARAMS,
  WEBSITE_URL,
};

export default CONFIG;
