import { createTheme  } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const Theme = {
  palette: {
    primary: {
      main: '#FFF',
      light: '#6bcac8',
      contrastText: '#35B6B4',
    },
    secondary: {
      main: '#fff',
      light: '#fff',
      contrastText: '#35B6B4',
    },
    chapter1: {
      main: '#0D2763',
    },
    chapter2: {
      main: '#B0D0EF',
    },
    chapter3: {
      main: '#E9425C',
    },
    chapter4: {
      main: '#4E9A6C',
    },
    chapter5: {
      main: '#FDC533',
    },
    error: {
      main: '#D64045',
    },
    warning: {
      main: '#F2B41B',
    },
    success: {
      main: '#5FAB80',
    },
    info: {
      main: '#233B57',
    },
    white: {
      main: '#FFFFFF',
      dark: '#f4f4f4',
      contrastText: '#000',
    },
    blue: {
      main: '#242D66',
      light: '#bdc0d099',
      ultralight: '#d3d3e2',
      dark: '#000091',
      contrastText: '#FFFFFF',
    },
    pink: {
      main: '#f192aa',
      light: '#ffc7ce',
      ultralight: '#fcf2f4',
      dark: '#be7386',
      contrastText: '#FFFFFF',
    },
    magenta: {
      main: '#e71f67',
      light: '#F1885B',
      ultralight: '#F1885B0C',
      dark: '#b31850',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", Arial, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 0.84,
      textTransform: 'initial',
    },
    h2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 26,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '32px',
      textTransform: 'initial',
    },
    h3: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.1,
      textTransform: 'initial',
    },
    h4: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    h5: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    h6: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.2rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.25,
    },
    body1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    body2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.2rem',
      fontWeight: 100,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.15rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.35,
    },
    button: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 15,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '29px',
      textTransform: 'uppercase',
    },
    overline: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '29px',
    },
    caption: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1.2857,
    },
  },
  components: {
    MuiChip: {      
      styleOverrides: {
        root: {
          paddingLeft: 12,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'display'},
          style: {            
            display: 'inline-block',
            textTransform: 'capitalize',
            padding: '8px 16px',
            margin: '8px !important',
            backgroundColor: '#4777AE',
            color: '#FFFFFF',
            minWidth: 100,
            textAlign: 'center',
            pointerEvents: 'none',
          },
        },
      ],
    },
  },
};

export default createTheme(Theme, frFR);
