import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import parse from '../../../../utils/parse';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import LogoUrl from '../../../../resources/logo.png';
import LinkedinIconUrl from '../../../../resources/icons/icon-linkedin.svg';
import DownloadIconUrl from '../../../../resources/icons/icon-download-white.svg';

import { 
  WEBSITE_URL,
  LINKEDIN_URL,
  MENU_HIERARCHY, 
  MENU_DESKTOP_WIDTH,
} from '../../../../config';

import ShareButton from './ShareButton';

import styles from '../styles';

class DesktopAppMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentHighlightedItem: null,
      isHover: false,
    }
  }
  /**
   * HANDLE
   */
  handleMouseEnter(evt, params = null) {
    const setState = {
      isHover: true,
    };
    if (params) {
      setState.currentHighlightedItem = params;
    }
    this.setState(setState);
  }
  
  handleMouseLeave(evt) {
    const { relatedTarget, toElement } = evt.nativeEvent;
    if (relatedTarget) {
      if (relatedTarget.classList.contains('bookmarkButton')) {
        return;
      }
      if (relatedTarget.closest('.subDrawer')) {
        return;
      }
    }
    if (toElement) {
      if (toElement.classList.contains('bookmarkButton')) {
        return;
      }
      if (toElement.closest('.subDrawer')) {
        return;
      }
    }
    this.setState({ isHover: false });
  }

  /**
   * RENDER
   */
  renderMainDrawer() {
    const { classes, page, fullscreen, fileURL, onNavigate, isMobile } = this.props;
    return (
      <Drawer
        className={classes.mainDrawer}
        variant="persistent"
        anchor="left"
        open={!fullscreen}
      >
        <Box className={classes.mainDrawerHeader}>
          <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
            <img src={LogoUrl} alt="Proparco" />
          </a>
        </Box>
        <List>
          {MENU_HIERARCHY.map(item => {
            const isFocus = page >= item.pages[0] && page <= item.pages[1];
            return (
              <ListItem key={item.label} className={`${classes.listItem} ${item.color} ${isFocus ? 'focus' : ''}`} disablePadding>
                <ListItemButton
                  className="bookmarkButton"
                  onClick={() => onNavigate(item.pages[0])}
                >
                  <ListItemText
                    className={`${classes.drawerLabel} ${item.color} category`}
                    primary={<Typography variant="h3" sx={{ fontWeight: isFocus ? 700 : 400 }}>{parse(item.label)}</Typography>}
                    secondary={<span className={`listItemFocus ${item.color}`}></span>}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>


        <Stack className={classes.socialList} justifyContent='center' style={{ backgroundColor: '#35B6B4', flexGrow: 1, maxHeight: '300px' }}>
          <Stack>
            <ShareButton classes={classes} />
            <ListItem key="download" className={classes.listItem} disablePadding>
              <ListItemButton
                onClick={() => {
                  // Event for Downloads stat with analytics
                  // window.gtag('event', 'download_pdf', {
                  //   'event_category': 'Proparco - Rapport d\'impact 2023',
                  //   'event_label': 'download_pdf_click'
                  // });
                  const link = document.createElement("a");
                  link.href = fileURL;
                  link.download = 'Proparco - Rapport d\'impact 2023.pdf';
                  link.click();
                }}
              >
                <ListItemIcon>
                  <img className={classes.menuIcon} src={DownloadIconUrl} alt="Télécharger le rapport" />
                </ListItemIcon>
                <ListItemText className={`${classes.drawerLabel} ${classes.whiteLabel}`} primary={<Typography variant="h4" color="primary">TÉLÉCHARGER LE RAPPORT</Typography>} />
              </ListItemButton>
            </ListItem>
          </Stack>
          <ListItem key="social" className={classes.listItem} disablePadding sx={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <ListItemButton className={isMobile ? classes.shareAndDownloadMobileButton : null} onClick={() => window.open(LINKEDIN_URL, '_blank')} sx={{ flexGrow: isMobile ? 0 : 1 }}>
              <ListItemIcon>
                <img className={classes.menuIcon} src={LinkedinIconUrl} alt="Linkedin" />
              </ListItemIcon>
              <ListItemText className={`${classes.drawerLabel} ${classes.whiteLabel}`} primary={<Typography variant="h4" color="primary">NOUS SUIVRE</Typography>} />
            </ListItemButton>
          </ListItem>
        </Stack>
      </Drawer>
    );
  }

  render() {
    const { classes, fullscreen } = this.props;
    return (
      <Stack
        className={classes.desktopMenuContainer}
        sx={{ width: fullscreen ? 0 : MENU_DESKTOP_WIDTH }}
        direction="row"
      >
        {this.renderMainDrawer()}
      </Stack>
    );
  }
}

DesktopAppMenu.defaultProps = {
  page: 0,
};

DesktopAppMenu.propTypes = {
  // props
  fullscreen: PropTypes.bool,
  page: PropTypes.number,
  fileURL: PropTypes.string,
  // funcs
  onNavigate: PropTypes.func.isRequired,
};

export default withStyles(styles)(DesktopAppMenu);
